export const environment = {
    apiBaseUrl: 'https://api.uat.farevantage.io/rail/v1',
    auth: {
        algorithm: 'RSA256',
        audience: 'https://ra-rail.revenueanalytics.io',
        clientID: 'XDJC0hhrnUby29SVuUasZXujYsU6SXXc',
        domain: 'ra-rail.eu.auth0.com',
        responseType: 'token id_token',
        scope: 'openid profile email'
    },
    production: true
};
